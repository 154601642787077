.add__user__modal__form {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}
.add__user__form__container {
    position: relative;
    flex: 1;
    display: flex;
    flex-flow: column;
}
.add__user__form__btn__container {
    position: relative;
    margin: 0;
    padding: .75em;
}
.add__user__form__admin__cb {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.add__user__form__admin__cb > div:first-child {
    margin-right: .5em;
}
.add__user__form__admin__cb h3 {
    margin: 0;
}
.add__user__btn__container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.75em;
    /* margin-top: .75em; */
}
.add__user__btn__container button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .5em;
    /* padding: .75em; */
}
.add__user__btn__container button.green {
    background: #00bf8f;
    background: -webkit-linear-gradient(to top, rgba(0, 21, 16,0.4), #00bf8f);
    background: linear-gradient(to top, rgba(0, 21, 16,0.4), #00bf8f);
}
.add__user__btn__container button.red {
    background: #e65970;
    background: -webkit-linear-gradient(to top, rgba(0, 21, 16,0.4), #e65970);
    background: linear-gradient(to top, rgba(0, 21, 16, 0.4), #e65970);    
}

.add__user__btn__container button .btn-icon {
    margin-right: 0.5em;    
}


.btn-grad {
    background-image: linear-gradient(to right, #D31027 0%, #EA384D  51%, #D31027  100%);
}
.btn-grad {
   margin: 10px;
   padding: 15px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
 }

 .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }
 