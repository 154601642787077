.panel_container {
    position: relative;
}



.heading {
    padding: .75em .25em;
    padding-bottom: .4em;
    font-size: 1.95rem;
    font-family: 'futurabold';
}

.description {
    padding: .5em;
    font-size: 1.5rem;
    border: 1px solid black;
    border-radius: 5px;
    background-color: hsl(200, 20%, 50%);
    color: snow;
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.205),
    inset -2px -2px 3px rgba(0, 0, 0, 0.205);
}