#msg-banner {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    /* bottom: .75em; */
    left: 50%;
    width: 85%;
    height: 75px;
    border-radius: .25em;
    color: snow;
    opacity: 0;
    transform: translate(-50%,0%) scale(0);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
#msg-banner.reveal {
    transform: translate(-50%,-100%) scale(1);
    opacity: 1;
}
#msg-banner .icon__container {
    position: relative;    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: .25em 0 0 .25em;
    padding: 0 .75em;
}
#msg-banner .banner__content {
    position: relative;
    flex: 1;
    height: 100%;
    padding-left: .75em;
}
#msg-banner .banner__content div:first-child {
    position: relative;
    padding: .5em;
}
#msg-banner h3 {
    margin: 0;
    padding: 0;
    font-family: 'Righteous', cursive;
    font-size: 1.25rem;
}

#msg-banner.error {
    background-color: #D34F73;
    color: snow;
}
#msg-banner.error .icon__container {
    background-color: #B42D51;
}
#msg-banner.success {
    background-color: #4C956C;
    color: snow;
}
#msg-banner.success .icon__container {
    background-color: #315E44;
}
#msg-banner.warning {
    background-color: #F18805;
    color: snow;
}
#msg-banner.warning .icon__container {
    background-color: #A05903;    
}
#msg-banner.info {
    background-color: #1D4E89;
    color: snow;
}
#msg-banner.info .icon__container {
    background-color: #123054;
}
#msg-banner.success svg {
    color: snow;
}
#msg-banner.error svg {
    color: snow;
}
