.organization__detail__view {
    position: relative;        
    width: 100%;
    height: 100%;
    padding: 0.5em .75em;
    overflow-y: scroll;
}
.org__detail__add__agent__div {
    position: relative;
    width: 100%;
    height: 175px;
    margin-bottom: 5.75em;
}
.org__detail__add__user__div {
    position: relative;
    width: 100%;
    height: 350px;
    margin-bottom: 5.75em;
}
.org__detail__add__agent__action__row {
    position: relative;
    display: flex;
    align-items: center;
    padding: .5em;
}
.org__detail__add__user__action__row {
    position: relative;
    display: flex;
    align-items: center;
    padding: .5em;
}
.org__detail__add__agent__action__row svg {
    cursor: pointer;
}
.org__detail__add__user__action__row svg {
    cursor: pointer;
}
.org__detail__add__agent__action__row svg:hover {
    transform: scale(1.1);
    color: green;
}
.org__detail__add__user__action__row svg:hover {
    transform: scale(1.1);
    color: green;
}
.org__detail__add__agent__title {
    font-family: var(--default-head-font);
    font-size: 1.5rem;
    margin-right: 0.5em;
}
.org__detail__add__user__title {
    font-family: var(--default-head-font);
    font-size: 1.5rem;
    margin-right: 0.5em;
}
.org__detail__add__agent__grid__row {
    position: relative;
    width: 100%;
    height: 100%;
}
.org__detail__add__user__grid__row {
    position: relative;
    width: 100%;
    height: 100%;
}
.row_copy_btn {
    background-color: transparent;
    border: none;
    outline: none;
    color: black;
}
.row_manage_btn {
    color: green;
}
.row_manage_btn .toggled {
    color: red;
}