.tileoptionone {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width:200px;
    height: 200px;
    border-radius: 5px 5px 0px 0px;
    background-color: snow;
    overflow: visible;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.left-nav-menu {
    position: relative;
    display: flex;
    width: 50px;
    height: 100%;
    background-color: #0D1B2A;
}

.left-edge {
    width: 10px;
    height: 100%;
    background-color: #519872;
}
.left-nav-btn {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    padding: 0.5em 0.25em;
}
.left-nav-btn span {
    padding: 0.25em 0.0em;
    cursor: pointer;
    color: snow;
}
.left-nav-btn span:hover svg {
    transform: scale(1.1);
    color: #5386E4;
}

.notification-badge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    padding-left: 1px;
    border-radius: 50%;
    z-index: 1;
    background-color: red;
    font-size: 0.6rem;
    color: snow;
    cursor: pointer;
}
.tile-content {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}
.tile-heading {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 0.5em 0em;
}

.tile-heading h6 {
    padding: 0px;
    margin: 0px;
    size: 1.35rem;
    font-family: futuraBold;
}
.tile-content-body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.tile-content-body span:hover svg {
    cursor: pointer;
    color: #519872;
    transform: scale(1.1);
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.151));
} 

.blob {
    position: absolute;
    bottom: 0.25em;
    right: 0.25em;
	background: black;
	border-radius: 50%;
	margin: 8px;
	height: 10px;
	width: 10px;
}

.blob.app-active {
    background: rgba(0, 255, 0, 1);
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 1);
    /* background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1); */
    animation: pulse-green 2s infinite;
}
.blob.app-deactive {
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
  }
  
  
  
  
@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
    }
}
@keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }


@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}