.stat__tile {
    position: relative;
    display: flex;
    flex-flow: column;    
    flex: 1;
    text-align: center;
    border-radius: 5px;
    background-color: var(--primary-lite-dark-color);
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.stat__tile__head {
    padding: .5em;
    font-family: var(--default-head-font);
    font-size: 1.25rem;
    color: snow;
    border-radius: 5px 5px 0 0;
    background-color: var(--primary-dark-color);
}
.stat__tile__stat {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: .75em;
    color: snow;
    
}
.stat__action {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: .75em;
    border-radius: 0 0 5px 5px;
    color: snow;
    background-color: var(--primary-lite-dark-color);
}
.stat__action span:last-child {
    cursor: pointer;
    transform: scale(1);
    transition: all 1s ease-in-out;
}
.stat__action span:last-child svg:hover {
    transform: scale(1.1);
    color: #00a651;
}