.drop-down {
    position: absolute;
    display: block;
    top: calc(100% + 10px);
    right: 0px;
    width: 200px;
    /* height: 200px; */
    z-index: 5;
    border-radius: 5px;
    /* padding: 0.5em; */
    background-color: #555;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    z-index: 1;
}
.drop-down h4 {
    padding: .25em;
    margin: 0;
    border-bottom: 1px solid grey;
    font-family: var(--default-head-font);
    color: rgba(50, 205, 50, 0.438);
    text-align: center;
}
.drop-down.show {
    display: block;
}
.drop-down.right {
    top: 3px;
    right: calc(100% + 15px);
}
.drop-down.top::after {
    content: "";
    position: absolute;
    bottom: calc(100% - 7.5px);
    width: 15px;
    height: 15px;
    right: 7.5px;
    background-color: rgba(85, 85, 85, 1);
    transform: rotate(45deg);
    
}
.drop-down.right::after {
    content: "";
    position: absolute;
    bottom: calc(100% - 7.5px);
    width: 15px;
    height: 15px;
    top: 15px;
    right: -7.5px;
    background-color: rgba(85, 85, 85, 1);
    transform: rotate(45deg);
    z-index: -1;
}
.drop-down ul {
    padding: 0px;
    margin: 0px;
}
.drop-down ul li {
    position: relative;
    display: flex;
    align-items: center;
    color: snow;
    padding: .5em .5em;
    border-bottom: 1px solid grey;
    cursor: pointer;
}
.drop-down ul li span {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 .25em;
}
.drop-down ul li span:last-child {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
}
.drop-down ul li:first-child {
    border-radius: 4px 4px 0 0 ;
    border-top: 0;
}
.drop-down ul li:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: 0;
}
.drop-down ul li:hover {
    background: #aaa;
}
