* , *:before, *:after{ 
    box-sizing:border-box; 
    -moz-box-sizing:border-box; 
    -webkit-box-sizing:border-box; 
    -ms-box-sizing:border-box;
}

#agent-detail-view {
    position: relative; 
    display: flex;
    flex-flow: column;       
    width: 100%;
    height: 100%;
    padding: 0.5em .75em;
    overflow-y: scroll;
    box-sizing: border-box;
}

#agent-detail-view nav {
    position: relative;
    margin: 0.75em 0;
    box-sizing: border-box;
}

#agent-detail-view nav .nav__row {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    /* width: 100%; */
    height: 100%;
    /* background-color: red; */
}

#agent-detail-view nav .nav__row .nav__icon {
    position: relative;
    padding: 0.5em;
    cursor: pointer;
    border: 1px solid transparent;
}

#agent-detail-view nav .nav__row .nav__icon:hover {
    border: 1px solid darkblue;
    border-radius: 10px;
    box-shadow: inset 0 0 40px green;
    color: darkgreen;
}

#agent-detail-view nav .nav__row .nav__icon.active {
    border: 1px solid darkblue;
    border-radius: 10px;
    box-shadow: inset 0 0 40px green;
    color: darkgreen;
}

#agent-detail-view .title {
    padding: 0.5em;
    text-align: center;
}
#agent-detail-view .title h2 {
    font-family: futuraBold;
    font-size: 2.75rem;
}
#agent-detail-view .form {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
}
#agent-detail-view .form form {
    position: relative;
    height: 100%;
}

#agent-detail-view .connection_detail_panel {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;  
    padding: 1em;  
    border: 1px solid black;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* background-color: blueviolet; */
    
}

#agent-detail-view .connection_detail_panel div {
    position: relative;
    width: 20%;
    text-overflow: ellipsis;
}