.container {
    position: relative;
    width: 100%;
    height: 175px;
    margin-bottom: 5.75em;
}

/***** action row css ******/
.action_row {
    position: relative;
    display: flex;
    align-items: center;
    padding: .5em;
}
.heading {
    font-family: var(--default-head-font);
    font-size: 1.5rem;
    margin-right: 0.5em;
}
/***************************/

.table_row {
    position: relative;
    width: 100%;
    height: 100%;
}
