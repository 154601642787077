.bill_detail {
    position: absolute;
    display: flex;
    flex-flow: column;
    top: 50%;
    left: 50%;     
    background-color: #373a44;
    border: 1px solid black;
    box-shadow: 0 4px 6px -2px rgba(0,0,0,0.1),0 10px 15px -3px rgba(0,0,0,0.15);
    border-radius: .5em;
    transform: translate(-50%,-50%);
    transition: height .4s ease-out;
}
.bill_detail_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding: .5em;
}

.close_btn {
    position: absolute;
    top: .5em;
    right: .5em;
    color: snow;
    cursor: pointer;
    z-index: 1;
}

.heading {
    position: relative;
    padding: .75em 1em;
    padding-bottom: .5em;
    text-align: center;
    font-family: futuraBold;
    font-size: 1.25rem;
    color: snow;
}

.sub_heading {
    position: relative;
    padding: .15em .5em;
    text-align: center;
    font-family: 1rem;
    color: snow;
}

.body {
    position: relative;
    padding: .75em 1em;
}