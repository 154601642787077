.billing_view {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: 1em;
}


/***** BILLING VIEW HEAD CSS STYLES *****/
.head {
    position: relative;
    width: 100%;
    padding: 3em;
    padding-bottom: 2em;
    font-family: futurabold;
    text-align: center;
}
.head h1 {
    font-size: 4rem;
}


/***** BILLING PRODUCT SELECT CSS STYLES *****/
.seat_select-container {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2em;
}
.seat_selector {
    width: 34%;
}

button.row_pay_button {
    background-color: transparent;
    outline: none;
    border: none;
    color: #00a651;
}
button.row_activate_button {
    background-color: transparent;
    outline: none;
    border: none;
    color: #00a651;
}
button.row_unpay_button {
    background-color: transparent;
    outline: none;
    border: none;
    color: #C41E3A;
}
button.row_activate_button.toggled {
    color: #C41E3A;    
}

.no_terms {
    background-color: transparent;
    outline: none;
    border: none;
    color: transparent;
}
.has_terms {
    background-color: transparent;
    outline: none;
    border: none;
    color: limegreen;
}


.grid_row {
    position: relative;
    width: 100%;
    height: 100%;
}




