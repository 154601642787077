/* #add_product_modal-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
} */
#add_product_modal-form .form-row {
    position: relative;
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
}
#add_product_modal-form .form-row .form-row-col {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 2em;
}
#add_product_modal-form .form-row .product_details-column {    
    border-right: 1px outset rgba(0, 0, 0, 0.25);
}
#add_product_modal-form .form-row .product_description-column {        
    border-left: 1px inset rgba(0, 0, 0, 0.25);    
}
#add_product_modal-form .button-row {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    padding: 1em .75em;
}
#add_product_modal-form .button-row .button-container {
    display: flex;
    justify-content: center;
    width: 100%;        
}
#add_product_modal-form .button-row .button-container button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: .5em;
}
#add_product_modal-form .button-container button .btn-icon {
    margin-right: 0.75em;
}