.top__grid__content {
    position:relative;
    padding: 2em 0.75em;
}
.top__grid__content div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top__grid__content div img {
    width: 75%;
    cursor:pointer;
}
.top__grid__content .glds-logo {
    position:absolute;
    left:0.75em;
    bottom:0.75em;
    width:125px;
    height:50px;    
}