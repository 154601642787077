.three_column_view {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    gap: .5em;    
    width: 100%;
    height: 100%;
    padding: .5em;
    padding-bottom: 0;
}




.column {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex:1;
}


