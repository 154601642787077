.date_picker {
    position: relative;
    width: 50%;
    margin: 0 auto;
}
.date_picker_inline {
    position: relative;
    width: 80%;
    margin: 0 auto;
    /* margin-bottom: .5em; */
}
.payment_date_inline {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
.payment_date_inline > div {
    flex: 1;
}
.payment_date_inline > div:first-child {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: .5em;
    font-size: 1.75rem;
    font-family: 'futuraBold';
}
.payment_date_inline input {
    position: relative;
    width: 100%;
    padding: .25em;
    height: calc(3.5rem + 2px);
    border: 0;
    outline: 0;
    border-radius: 5px;
}

/***** DATE PICKER LABEL STYLE *****/
.picker_label {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
}
.picker_label > div {
    flex: 1;
    text-align: center;
    font-size: 1.25rem;
    font-family: 'futurabold';
}


/***** DATE PICKER INPUT STYLE *****/
.date_input_container {
    position: relative;
    display: flex;
    gap: .5em;
    flex-wrap: nowrap;
    padding: 1em 0em;
}
.date_input_container > div {
    flex: 1;
}
.date_input_container > div input {
    width: 100%;
    height: calc(3.5rem + 2px);
    border-radius: 5px;
    border: 0;
    padding: 0 .45em;
}