.form__modal {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(85, 85, 85, .95);
    z-index: 9;
    justify-content: center;
    align-items: center;
}
.modal__container {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background-color: aliceblue;
}
.title {
    text-align: center;
}
.title h2 {
    font-family: futuraBold;
    font-size: 3.25rem;
    margin: 0;
    padding: .25em .5em;    
}
.form {
    position: relative;
    flex: 1;
}