.mobile__content__grid {
    position:relative;
    width:100%;
    height:100%;
  }
  
  .bg-image {
    width:100%;
    height:100%;
    filter:opacity(90%);
  }
  
  .mobile__content__grid .bgd__gradient {
    position:absolute;
    width:100%;
    height:100%;
    right:0px;
    top:0px;
    background-image: unset;
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(166,178,192,0.3) 7%, rgba(65,90,119,0.54) 15%, rgba(65,90,119,0.75) 22%, rgba(65,90,119,0.96) 38%, rgba(65,90,119,1) 55%);
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(166,178,192,0.3) 7%,rgba(65,90,119,0.54) 15%,rgba(65,90,119,0.75) 22%,rgba(65,90,119,0.96) 38%,rgba(65,90,119,1) 55%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(166,178,192,0.3) 7%,rgba(65,90,119,0.54) 15%,rgba(65,90,119,0.75) 22%,rgba(65,90,119,0.96) 38%,rgba(65,90,119,1) 55%);
  }

.mobile__content__view {
    position:relative;
    display: flex;
    flex-flow: column;
    width:100%;
    height:100%;
}