.dashboard__view {
    position: relative;
    width: 100%;
    height: 100%;    
}
.dashboard__head > div {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1.25em;
}
/* .dashboard__view > div {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .75em;
} */
.dashboard__view > div h1 {
    text-align: center;
    font-family: var(--default-head-font);
    color: var(--primary-lite-dark-color);
}
.stats__row {
    position: relative;
    display: flex;
    /* flex-wrap: nowrap; */
    gap: 3em;
    padding: .75em 5em;
    /* width: 100%; */
}
