.user__detail-view {
    position: relative; 
    display: flex;
    flex-flow: column;       
    width: 100%;
    height: 100%;
    padding: 0.5em .75em;
    overflow-y: scroll;
    box-sizing: border-box;
}
.user__detail-view nav {
    position: relative;
    margin: 0.75em 0;
    box-sizing: border-box;
}
.user__detail-view nav .nav__row {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    /* width: 100%; */
    height: 100%;
    /* background-color: red; */
}
.user__detail-view nav .nav__row .nav__icon {
    position: relative;
    padding: 0.5em;
    cursor: pointer;
    border: 1px solid transparent;
}

.user__detail-view nav .nav__row .nav__icon:hover {
    border: 1px solid darkblue;
    border-radius: 10px;
    box-shadow: inset 0 0 40px green;
    color: darkgreen;
}
.user__detail-view nav .nav__row .nav__icon.active {
    border: 1px solid darkblue;
    border-radius: 10px;
    box-shadow: inset 0 0 40px green;
    color: darkgreen;
}
.user__detail-view .title {
    padding: 0.5em;
    text-align: center;
}
.user__detail-view .title h2 {
    font-family: futuraBold;
    font-size: 2.75rem;
}
.user__detail-view .form {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
}
.user__detail-view .form form {
    position: relative;
    height: 100%;
}
.cb_field {
    justify-content: center;
}
.add__user__form__admin__cb {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.add__user__form__admin__cb div:first-child {
    margin-right: 2em;
}
.add__user__form__admin__cb h3 {
    margin: 0;
    padding: 0;
}

#user-detail-view .btm__nav {
    position: relative;
    width: 100%;
    padding: .75em .5em;
}

#user-detail-view .user__agent__assign-view {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: 0 .25em;
    /* background-color: blueviolet; */
    
}

#user-detail-view .user__agent__assign-view .heading {
    position: relative;
    padding: 1em;
    padding-top: 0;
    text-align: center;
}
#user-detail-view .user__agent__assign-view .heading h2 {
    font-family: var(--default-head-font);
    font-size: 2.25rem;
    margin: 0;
}
#user-detail-view .user__agent__assign-view .option {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .5em;
}
#user-detail-view .user__agent__assign-view .body {
    position: relative;
    display: flex;
    flex-flow: column;
    flex: 1;
    padding: 0 .75em;   
}
#user-detail-view .user__agent__assign-view .body h4 {
    font-size: 1.25rem;
}
#user-detail-view .user__agent__assign-view .body ul {
    position: relative;
    width: 100%;
    height: 100%;
    padding: .5em;
    border: 1px solid black;
    border-radius: 5px;
}
#user-detail-view .user__agent__assign-view .dropdown {
    position: relative;
    padding: 1em;
}
/* .dropdown-menu {
    width: 200px !important;
    height: 400px !important;
} */
#user-detail-view .list-group-item {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding-left: 0;
    border-top: 1px solid rgba(255, 250, 250, 0.5); 
}
#user-detail-view .list-group-item:last-child {
    border-bottom: 1px solid rgba(255, 250, 250, 0.5);
}
#user-detail-view .list-group-item > div:first-child {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 .75em;
}
#user-detail-view .list-group-item > div:last-child div h6 {
    font-family: var(--default-head-font);
    margin: 0;
}
#user-detail-view .list-group-item > div:last-child div span {
    font-size: .8rem;
    color: silver;
}





/***** NEW STYLES *****/
/***** Licensed user list styles *****/
#user-detail-view .license__user__item {
    position: relative;
    display: flex;    
    width: 100%;
    margin-bottom: 1.25em;
    border: 1px solid var(--primary-dark-color);
    border-radius: 0.25em;
    background-color: var(--primary-lite-dark-color);
    overflow: hidden;
    cursor: pointer;
}
#user-detail-view .license__user__item.is-error {
    background-color: var(--primary-lite-dark-color);
    animation: shake .09s linear 4;
}
#user-detail-view .license__user__item .license__user__item__banner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--glds-green);    
    font-size: 1.5rem;
    font-family: futurabold;
    color: snow;
    z-index: 5;
    opacity: 0;
    transform-origin: left; 
    transform: translateX(100%) scale(.5);       
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}
#user-detail-view .license__user__item .license__user__item__banner.is-error {
    background-color: transparent;
    background-color: var(--glds-red);
    opacity: .9;
}
#user-detail-view .license__user__item .license__user__item__banner.is-success {
    background-color: transparent;
    background-color: var(--glds-green);
    opacity: .9;
}

#user-detail-view .license__user__item .license__user__item__banner.is-visible {    
    opacity: 1;
    transform: translateX(0) scale(1);
    transform-origin: right;
}
#user-detail-view .license__user__item.is-loading {
    
    opacity: 0.5;
}
#user-detail-view .license__user__item .license__user__item__loading {
    position: absolute;
    top: 50%;
    right: .75em;
    transform: translateY(-50%);
    opacity: 0;
}
#user-detail-view .license__user__item.is-loading .license__user__item__loading {
    opacity: 1;
}

#user-detail-view .license__user__item .license__user__item__cb-wrapper {
    position: relative;
    display: flex;    
    justify-content: center;
    align-items: center;
    padding: .75em;        
}
#user-detail-view .license__user__item .license__user__item__cb-wrapper.is-hidden {
    width: 0;
    padding: 0;
}
#user-detail-view .license__user__item .license__user__item__cb-wrapper.is-hidden input { 
    opacity: 0;
}
#user-detail-view .license__user__item .license__user__item__cb-wrapper.is-hidden .checkmark {
    position: relative;
    transform: translateX(-100%);
} 
#user-detail-view .license__user__item .license__user__item__cb-wrapper.is-hidden .checkmark::after {
    opacity: 0;
}
#user-detail-view .license__user__item .license__user__item__details-wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: .75em;
    flex: 1;
}
.license__user__item .license__user__item__details-wrapper span:first-child {
    font-family: futuraBold;
    font-size: 1.75rem;
    color: var(--base-font-color);
}
.license__user__item .license__user__item__details-wrapper span:last-child {
    font-size: 1rem;
    color: var(--heading-light-color);
}