.license_detail_view {
    position: relative; 
    display: flex;
    flex-flow: column;       
    width: 100%;
    height: 100%;
    padding: 0.5em 1.5em;
    overflow-y: hidden;
    box-sizing: border-box;
}
.license_detail_nav {
    position: absolute;
    top: 1em;
    right: 1em;
}
.nav_icon {
    position: relative;
    cursor: pointer;
    border: 1px solid transparent;
}
.nav_icon:hover {
    border: 1px solid darkblue;
    border-radius: 10px;
    box-shadow: inset 0 0 40px green;
    color: darkgreen;
}
.nav__icon.active {
    border: 1px solid darkblue;
    border-radius: 10px;
    box-shadow: inset 0 0 40px green;
    color: darkgreen;
}


.title {
    padding: 0.5em;
    text-align: center;
}
.title h2 {
    font-family: futuraBold;    
    color: #0d1b2aab;
    font-size: 3rem;
    margin: 0;
}
.title span {
    color: #0d1b2a;
}
.subtitle {
    /* padding: 0.5em; */
    text-align: center;
}
.subtitle h4 {
    font-size: 2.25rem;
    margin: 0;
    padding: .5em;
    padding-top: 0;
}

.detail_view {
    position: relative;
    flex: 1;
    border: 1px solid black;
    border-radius: 10px;
    background-color: #c9daea;
}
.detail_view_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.app_license_detail {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    /* gap: .5em; */
    width: 100%;
    height: 100%;
}
.svc_license_detail {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    gap: .5em;
    width: 100%;
    height: 100%;
}
.license_detail_heading {
    padding: .75em .25em;
    padding-bottom: .25em;
    font-size: 1.95rem;
    font-family: 'futurabold';
    align-self: flex-start;
}
.detail_col {
    position: relative;
    flex: 1;
}
.license_detail_col {
    position: relative;
    display: flex;
    flex-flow: column;
    /* justify-content: center; */
    align-items: center;
    padding: 1.5em;
}
/* .license_user_col {
    padding: 1.5em 0;
    padding-top: .75em;
} */
.license_billing_col {
    padding: 1.5em;
}
.form_container {
    position: relative;
    width: 100%;
    padding: 1em;
    border: 1px solid black;
    border-radius: 10px;
    background-color: hsl(200, 20%, 50%);
}

.user__assign-view {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: .25em .25em;
    padding-bottom: 0;
}
.heading {
    position: relative;
    padding: .2em;
    padding-top: 0;
    text-align: center;
}
.heading h2 {
    font-family: var(--default-head-font);
    font-size: 2.25rem;
    margin: 0;
}
.body {
    position: relative;
    /* display: flex;
    flex-flow: column; */
    width: 100%;
    flex: 1;
    /* padding: 0 .75em;  */
    /* background-color: #0ab438;   */
}
.table_container {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 5px;
    overflow-y: scroll;
}
.table_container::-webkit-scrollbar {
    width: 0;
}
.body h4 {
    font-size: 1.25rem;
    margin-bottom: .75em
}
.sub_heading {
    padding-bottom: .25em;
    text-align: center;
}
.body > div {
    text-align: center;
}
.body ul {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1.25em;
    border-radius: 5px;    
    /* background-color: blueviolet; */
}
.seat_number_details {
    padding: 0.75em;
    font-size: 1.25rem;
    font-family: 'futuraBold';
    display: flex;
    width: 100%;
    justify-content: space-around;
}



.license__user__item {
    position: relative;
    display: flex;    
    width: 100%;
    margin-bottom: 1.25em;
    padding: .75em;
    border: 1px solid var(--primary-dark-color);
    border-radius: 0.25em;
    background-color: var(--primary-lite-dark-color);
    overflow: hidden;
    cursor: pointer;
}
.license__user__item:hover {
    background-color: red;
}
.item-loading {
    position: absolute;
    top: 50%;
    right: .75em;
    transform: translateY(-50%);
    opacity: 0;
}
.item__cb-wrapper {
    position: relative;
    display: flex;    
    justify-content: center;
    align-items: center;
    padding: .75em;
}
.item__cb-wrapper .is-hidden {
    width: 0;
    padding: 0;
}
.item__cb-wrapper .is-hidden input {
    opacity: 0;
}
.is-hidden .checkmark {
    position: relative;
    transform: translateX(-100%);
}
.is-hidden .checkmark::after {
    opacity: 0;
}
.cb_container {
    position: relative;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.cb_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 25px;
    background-color: #eee;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: #fff;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 9%);
}
.checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: #0ab438;  
}
.cb_container:hover input ~ .checkmark {
    background-color: #fff;
}
.cb_container input:disabled ~ .checkmark {
    opacity: 0.3;
    cursor: default;
}
.cb_container input:checked ~ .checkmark {
    background-color: #2196F3;
    background-color: var(--glds-green);
    outline: 0;
}
.cb_container input:checked ~ .checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: #0ab438;
}
.cb_container input:focus ~ .checkmark {
    outline: none;
    border: none;
    box-shadow: 0 0 5px  rgb(119, 141, 169);
    border: 1px solid rgb(119, 141, 169);
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.cb_container input:checked ~ .checkmark:after {
    display: block;
}
.cb_container .checkmark:after {
    left: 9px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.cb_container .checkmark.changed:after {
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.item__details-wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: .5em;
    padding-left: 1em;
    flex: 1;
}
.item__details-wrapper span:first-child {
    font-family: futuraBold;
    font-size: 1.75rem;
    color: var(--base-font-color);
}
.item__details-wrapper span:last-child {
    font-size: 1rem;
    color: var(--heading-light-color);
}



/* 
.license__user__item.is-loading .license__user__item__loading {
    opacity: 1;
}
*/
.license__user__item__banner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--glds-green);    
    font-size: 1.5rem;
    font-family: futurabold;
    color: snow;
    z-index: 5;
    opacity: 0;
    transform-origin: left; 
    transform: translateX(100%) scale(.5);       
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}
.license__user__item__banner.is-success {
    background-color: transparent;
    background-color: var(--glds-green);
    opacity: .9;
}



.billing_details {
    width: 100%;
}
.billing_details-head {
    padding: .75em .25em;
    padding-bottom: .25em;
    font-size: 1.95rem;
    font-family: 'futurabold';
}
.billing_details-form {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: .5em;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 1rem;
    background-color: hsl(200, 20%, 50%);
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.205),
     inset -2px -2px 3px rgba(0, 0, 0, 0.205);    
}
.activate_panel_btn {
    position: absolute;
    top: 1em;
    right: 1.5em;
    width: 2em;
    height: 2em;
    z-index: 5;
}
.activate_panel_btn_wrapper {
    color: lime;
    cursor: pointer;
}
.activate_panel_btn_wrapper svg:hover {
    transform: scale(1.1);
}
.billing_details-field {
    width: 100%;
}
.billing_details_terms-container {
    position: relative;
    padding: .75em 0em;
    padding-bottom: 1em;
}

/*** TERMS BUTTON CONTAINER CSS ***/
.terms_btn_container {
    position: relative;
    padding: .75em 0em;
    padding-bottom: 1em;
}
.terms_btn_wrapper {
    position: relative;
    display: flex;    
    border: 1px solid black;
    border-radius: 10px;
    width: 50%;
    margin: 0 auto;
}
.terms_btn_wrapper > div {
    flex: 1;    
    text-align: center;
    font-weight: 700;
}
.terms_btn_wrapper > div button {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    background-color: #c9daea;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: .5em;
}
.terms_btn_wrapper > div:first-child button {
    border-radius: 10px 0 0 10px;
}
.terms_btn_wrapper > div:last-child button {
    border-radius: 0 10px 10px 0;
}
.terms_btn_wrapper > div:first-child button.active-button {
    background-color: hsla(120, 100%, 50%, 0.5);
    /* background-color: hsl(209, 44%, 50%); */
}
.terms_btn_wrapper > div:last-child button.active-button {
    background-color: hsla(120, 100%, 50%, 0.5);
    /* background-color: hsl(209, 44%, 50%); */
}
.terms_btn_wrapper > div:first-child {
    border-right: 1px solid black;
}
/*** TERMS BUTTON CONTAINER CSS END ***/

.billing_details-terms {
    position: relative;
    display: flex;    
    border: 1px solid black;
    border-radius: 10px;
    width: 50%;
    margin: 0 auto;
}
.billing_details-terms > div {
    flex: 1;    
    text-align: center;
    font-weight: 700;
}
.billing_details-terms > div button {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    background-color: #c9daea;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: .5em;
}
.billing_details-terms > div:first-child button {
    border-radius: 10px 0 0 10px;
}
.billing_details-terms > div:last-child button {
    border-radius: 0 10px 10px 0;
}
.billing_details-terms > div:first-child button.active-button {
    background-color: hsla(120, 100%, 50%, 0.5);
    /* background-color: hsl(209, 44%, 50%); */
}
.billing_details-terms > div:last-child button.active-button {
    background-color: hsla(120, 100%, 50%, 0.5);
    /* background-color: hsl(209, 44%, 50%); */
}
.billing_details-terms > div:first-child {
    border-right: 1px solid black;
}
.billing_range-container {
    position: relative;
    width: 50%;
    margin: 0 auto;
}
.billing_range-labels {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
}
.billing_range-picker {
    position: relative;
    display: flex;
    gap: .5em;
    flex-wrap: nowrap;
    padding: 1em 0em;
}
.billing_range-labels > div {
    flex: 1;
    text-align: center;
    font-size: 1.25rem;
    font-family: 'futurabold';
}
.billing_range-picker > div {
    flex: 1;
}
.billing_range-picker > div input {
    width: 100%;
    height: calc(3.5rem + 2px);
    border-radius: 5px;
    border: 0;
    padding: 0 .45em;
    font-size: 1.75rem;
    font-weight: 700;
}
.billing_range-picker > div input.past_due {
    color: red;
}
.billing_term_price {
    position: relative;
    width: 50%;
    padding-bottom: .5em;
    margin: 0 auto;
}
.billing_term_price input {
    
    font-weight: 700;
}
.billing_term_price input:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(239, 239, 239, 0.3);
}
.payment_date_wrapper {
    position: relative;
    width: 80%;
    margin: 1.5em auto;
    margin-bottom: .5em;
}
.payment_date {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
.payment_date > div {
    flex: 1;
}
.payment_date > div:first-child {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: .5em;
    font-size: 1.5rem;
    font-family: 'futuraBold';
}
.payment_date input {
    position: relative;
    width: 100%;
    padding: .25em;
    height: calc(3.5rem + 2px);
    border: 0;
    outline: 0;
    border-radius: 5px;
    font-size: 1.75rem;
    font-weight: 700;
}

.pay_method_wrapper {
    position: relative;
    width: 50%;
    margin: 0 auto;
}
.pay_method_wrapper select:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(239, 239, 239, 0.3);
}
.payment_button_wrapper {
    position: relative;
    padding: .75em .75em;
}
.payment_button {
    position: relative;
    width: 100%;
    padding: .25em;
    font-size: 2rem;
    border-radius: 5px;

}
.payment_button:active {
    transform: scale(.9);
}
.payment_button:hover {
    background-color: darkgray;
}