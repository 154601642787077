/***** PANEL CONTAINER STYLES *****/
.new_customer_panel {    
    width: 100%;
}

/***** PANEL HEADING STYLES *****/
.heading {
    padding: .75em .25em;
    padding-bottom: .25em;
    font-size: 1.95rem;
    font-family: 'futurabold';
}




/**** FORM CONTAINER STYLES *****/
.form {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2em .5em;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 1rem;
    background-color: hsl(200, 20%, 50%);
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.205),
     inset -2px -2px 3px rgba(0, 0, 0, 0.205);
    text-align: center;
}
.fields {
    width: 100%;
}


/***** AMOUNT TEXT FIELD STYLES *****/
.text_fields {
    position: relative;
    width: 50%;
    padding-bottom: .5em;
    margin: 0 auto;
    margin-bottom: 1em;
}
.cb_fields {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    gap: 1.5em;
    width: 50%;
    padding-bottom: .5em;
    margin: 0 auto;
    margin-bottom: 1em;
}
.cb_fields span {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
}
.text_fields input {    
    font-weight: 700;
}
.text_fields input:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(239, 239, 239, 0.3);
}