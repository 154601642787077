/***** Checkbox container element styles *****/
#we-cb__input-div {
    /* Custom properties */

    position: relative;
    margin-bottom: 1em;
}

/***** Checkbox and label wrapper styles *****/
#we-cb__input-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/***** Checkbox label styles *****/
#we-cb__input-wrapper .we-cb__input-label:first-child {
    margin-right: .5em;
}
#we-cb__input-wrapper h3 {
    font-size: 1.15rem;
    font-family: 'Secular One', sans-serif;
    color: #E6EBE0;
    margin: 0;
    text-transform: capitalize;
}


/***** Checkbox input wrapper styles *****/
#we-cb__input {
    display: flex;
    flex-wrap: nowrap;
    width: 60px;
    height: 30px;
}
#we-cb__input .we-cb-wrapper {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
#we-cb__input:focus-visible {
    outline: 0;
    border: 0;
}

/***** Checkbox input element styles *****/
#we-cb__input .we-cb-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/***** Visual checkbox span element styles *****/
.we-cb__checkmark {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0px;    
    height: 30px;
    width: 60px;
    border-radius: 5px;
    border: 1px solid black;
}
.we-cb__checkmark:focus-visible {
    border: 0;
    outline: 0;
}
.we-cb__checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: var(--accent-green-color);  
}
#we-cb__input .we-cb-wrapper:hover input ~ .we-cb__checkmark {
    /* background-color: #fff; */
    background-color: transparent;
}
#we-cb__input .we-cb-wrapper input:disabled ~ .we-cb__checkmark {
    opacity: 0.3;
    cursor: default;
}
#we-cb__input .we-cb-wrapper input:checked ~ .we-cb__checkmark {
    background-color: transparent;
    /* background-color: green; */
    /* width: 100%;
    height: 100%; */
}
#we-cb__input .we-cb-wrapper input:checked ~ .we-cb__checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: var(--accent-green-color);
}
#we-cb__input .we-cb-wrapper input:focus ~ .we-cb__checkmark {
    outline: 0;
    border: 0;
    box-shadow: 0 0 5px  rgb(119, 141, 169);
    border: 1px solid rgb(119, 141, 169);
}
#we-cb__input .we-cb-wrapper input:active ~ .we-cb__checkmark {
    outline: 0;
    border: 0;
    background-color: transparent;
    /* box-shadow: 0 0 5px  rgb(119, 141, 169); */
    /* border: 1px solid rgb(119, 141, 169); */
}
.we-cb__checkmark:after {
    content: "";
    position: absolute;
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    transform: translateX(31px);
    transition: transform 0.2s;
}
#we-cb__input .we-cb-wrapper input:checked ~ .we-cb__checkmark:after {
    background-color: var(--accent-green-color);
    transform: translateX(0px);
}
#we-cb__input .we-cb-wrapper .we-cb__checkmark:after {
    background-color: #ED6A5A;     
    transform: translateX(30px);  
    
}
#we-cb__input .we-cb-wrapper .we-cb__checkmark.changed:after {
    border: solid black;
    border-width: 0 6px 6px 0;
}
#we-cb__input .we-cb-wrapper:focus-visible {
    border: 0;
    outline: 0;
}
.we-cb__checkmark:focus-visible {
    border: 0;
    outline: 0;
}