.container {
    position: relative;
    padding: .75em 0em;
    padding-bottom: 1em;
}
.wrapper {
    position: relative;
    display: flex;
    width: 100%;
    margin: 0 auto;
}

.btn_container {
    flex: 1;    
    text-align: center;
    font-weight: 700;
}
.wrapper button {
    flex:1;
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    background-color: #c9daea;
    border-right: 1px solid black;
    width: 100%;
    height: 100%;
    padding: .5em;
    text-align: center;
    font-weight: 700;
    text-transform: capitalize;
}
.wrapper button:first-child {
    border-radius: 10px 0 0 10px;
}
.wrapper button:last-child {
    border-radius: 0 10px 10px 0;
    border: 0;
}
.wrapper button.active_button {
    background-color: hsla(120, 100%, 50%, 0.5);
}
/* .btn_container button.active_button {
    background-color: hsla(120, 100%, 50%, 0.5);
} */