/***** PANEL CONTAINER STYLES *****/
.panel_container {
    width: 100%;
}

/***** PANEL HEADING STYLES *****/
.heading {
    padding: .75em .25em;
    padding-bottom: .25em;
    font-size: 1.95rem;
    font-family: 'futurabold';
}

/***** PANEL LABEL STYLES *****/
.label {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
}
.label > div {
    flex: 1;
    text-align: center;
    font-size: 1.25rem;
    font-family: 'futurabold';
}


/**** FORM CONTAINER STYLES *****/
.form_container {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2em 2em;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 1rem;
    background-color: hsl(200, 20%, 50%);
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.205),
     inset -2px -2px 3px rgba(0, 0, 0, 0.205);    
}
.form_fields {
    width: 100%;
}

/***** AMOUNT TEXT FIELD STYLES *****/
.fee_text_field {
    position: relative;
    width: 100%;
    padding-bottom: .5em;
    margin: 0 auto;
}
.fee_text_field input {    
    font-weight: 700;
}
.fee_text_field input:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(239, 239, 239, 0.3);
}


/***** PAYMENT TYPE SELECT STYLES *****/
.pay_method_select {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-bottom: .5em;
}

.button_row {
    position: relative;
    padding: .75em .75em;
    padding-bottom: .5em;
}










.activate_panel_btn {
    position: absolute;
    top: 1em;
    right: 1.5em;
    width: 2em;
    height: 2em;
    z-index: 5;
}
.activate_panel_btn_wrapper {
    color: lime;
    cursor: pointer;
}
.activate_panel_btn_wrapper svg:hover {
    transform: scale(1.1);
}

.billing_details_terms-container {
    position: relative;
    padding: .75em 0em;
    padding-bottom: 1em;
}

/*** TERMS BUTTON CONTAINER CSS ***/
.btn_container {
    position: relative;
    padding: .75em 0em;
    padding-bottom: 1em;
}
.btn_wrapper {
    position: relative;
    display: flex;    
    border: 1px solid black;
    border-radius: 10px;
    width: 50%;
    margin: 0 auto;
}
.btn_wrapper > div {
    flex: 1;    
    text-align: center;
    font-weight: 700;
}
.btn_wrapper > div button {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    background-color: #c9daea;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: .5em;
}
.btn_wrapper > div:first-child button {
    border-radius: 10px 0 0 10px;
}
.btn_wrapper > div:last-child button {
    border-radius: 0 10px 10px 0;
}
.btn_wrapper > div:first-child button.active-button {
    background-color: hsla(120, 100%, 50%, 0.5);
    /* background-color: hsl(209, 44%, 50%); */
}
.btn_wrapper > div:last-child button.active-button {
    background-color: hsla(120, 100%, 50%, 0.5);
    /* background-color: hsl(209, 44%, 50%); */
}
.btn_wrapper > div:first-child {
    border-right: 1px solid black;
}
/*** TERMS BUTTON CONTAINER CSS END ***/

.billing_details-terms {
    position: relative;
    display: flex;    
    border: 1px solid black;
    border-radius: 10px;
    width: 50%;
    margin: 0 auto;
}
.billing_details-terms > div {
    flex: 1;    
    text-align: center;
    font-weight: 700;
}
.billing_details-terms > div button {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    background-color: #c9daea;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: .5em;
}
.billing_details-terms > div:first-child button {
    border-radius: 10px 0 0 10px;
}
.billing_details-terms > div:last-child button {
    border-radius: 0 10px 10px 0;
}
.billing_details-terms > div:first-child button.active-button {
    background-color: hsla(120, 100%, 50%, 0.5);
    /* background-color: hsl(209, 44%, 50%); */
}
.billing_details-terms > div:last-child button.active-button {
    background-color: hsla(120, 100%, 50%, 0.5);
    /* background-color: hsl(209, 44%, 50%); */
}
.billing_details-terms > div:first-child {
    border-right: 1px solid black;
}
.billing_range-container {
    position: relative;
    width: 50%;
    margin: 0 auto;
}
.billing_range-labels {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
}
.billing_range-picker {
    position: relative;
    display: flex;
    gap: .5em;
    flex-wrap: nowrap;
    padding: 1em 0em;
}
.billing_range-labels > div {
    flex: 1;
    text-align: center;
    font-size: 1.25rem;
    font-family: 'futurabold';
}
.billing_range-picker > div {
    flex: 1;
}
.billing_range-picker > div input {
    width: 100%;
    height: calc(3.5rem + 2px);
    border-radius: 5px;
    border: 0;
    padding: 0 .45em;
    font-size: 1.75rem;
    font-weight: 700;
}
.billing_range-picker > div input.past_due {
    color: red;
}

.billing_term_price input {
    
    font-weight: 700;
}
.billing_term_price input:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(239, 239, 239, 0.3);
}
.payment_date_wrapper {
    position: relative;
    width: 80%;
    margin: 1.5em auto;
    margin-bottom: .5em;
}
.payment_date {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
.payment_date > div {
    flex: 1;
}
.payment_date > div:first-child {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: .5em;
    font-size: 1.5rem;
    font-family: 'futuraBold';
}
.payment_date input {
    position: relative;
    width: 100%;
    padding: .25em;
    height: calc(3.5rem + 2px);
    border: 0;
    outline: 0;
    border-radius: 5px;
    font-size: 1.75rem;
    font-weight: 700;
}

.pay_method_wrapper {
    position: relative;
    width: 50%;
    margin: 0 auto;
}
.pay_method_wrapper select:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(239, 239, 239, 0.3);
}
.payment_button_wrapper {
    position: relative;
    padding: .75em .75em;
    /* z-index: 999999999; */
}
.payment_button {
    position: relative;
    width: 100%;
    padding: .25em;
    font-size: 2rem;
    border-radius: 5px;    
    z-index: 999;
}
.payment_button:active {
    transform: scale(.3);
}
.payment_button:hover {
    background-color: red;
}