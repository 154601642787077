* , *:before, *:after{ 
    box-sizing:border-box; 
    -moz-box-sizing:border-box; 
    -webkit-box-sizing:border-box; 
    -ms-box-sizing:border-box;
}

/* MODAL FORM DEFAULT CSS STYLES */
.modal-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
}
.select-row {
    position: relative;
}
.select-container {
    position: relative;
    width: 33%;
    margin: 0 auto;
}
.form-row {
    position: relative;
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
}
/* MODAL FORM DEFAULT CSS STYLES END*/

.add_product-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1.15em 1.5em;
    padding-bottom: 0.25em;
}

.product_license-form {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background-color: #c9daea;
    border: 1px solid black;
    border-radius: 10px;
}
.product_license-form {
    position: relative;
    padding: .5em .75em;
}
.product_license-form h1,
.product_license-form h4 {
    text-align: center;
    margin: 0;
}
.product_license-form .form-floating {
    position: relative;
    display: flex;
    justify-content: center;
    width: 50%;
    
}




.seat_select-container {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
}
.seat_selector {
    width: 34%;
}


.license_detail-container {
    position: relative;
    flex: 1;    
}
.license_detail-view {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    gap: .5em;    
    width: 100%;
    height: 100%;
    padding: .5em;
    padding-bottom: 0;
}
.seat_details-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex:1;
}
.user_details-container {
    position: relative;
    flex:1;    
}
.billing_details-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex:1;
}
.seat_number_details {
    padding: 0.75em;
    font-size: 1.25rem;
    font-family: 'futuraBold';
    display: flex;
    width: 100%;
    justify-content: space-around;
}









.user__assign-view {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: .25em .25em;
    padding-bottom: 0;
}
.heading {
    position: relative;
    padding: .2em;
    padding-top: 0;
    text-align: center;
}
.sub_heading {
    padding-bottom: .25em;
}
.heading h2 {
    font-family: var(--default-head-font);
    font-size: 2.25rem;
    margin: 0;
}
.body {
    position: relative;
    /* display: flex;
    flex-flow: column; */
    width: 100%;
    flex: 1;
    /* padding: 0 .75em;  */
    /* background-color: #0ab438;   */
}
.body h4 {
    font-size: 1.25rem;
    margin-bottom: .25em
}
.table_container {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 5px;
    overflow-y: scroll;
}
.table_container::-webkit-scrollbar {
    width: 0;
}
.body ul {
    position: relative;
    display: block;
    padding: 0;
    padding: .75em 1.25em;
    /* width: 100%; */
    /* background-color: blueviolet; */
}



.license__user__item {
    position: relative;
    display: flex;    
    width: 100%;
    margin-bottom: 1.25em;
    padding: .75em;
    border: 1px solid var(--primary-dark-color);
    border-radius: 0.25em;
    background-color: var(--primary-lite-dark-color);
    overflow: hidden;
    cursor: pointer;
}
.body ul .license__user__item:last-child {
    margin-bottom: 0;
}
.license__user__item:hover {
    background-color: red;
}
.item-loading {
    position: absolute;
    top: 50%;
    right: .75em;
    transform: translateY(-50%);
    opacity: 0;
}
.item__cb-wrapper {
    position: relative;
    display: flex;    
    justify-content: center;
    align-items: center;
    padding: .75em;
}
.item__cb-wrapper .is-hidden {
    width: 0;
    padding: 0;
}
.item__cb-wrapper .is-hidden input {
    opacity: 0;
}
.is-hidden .checkmark {
    position: relative;
    transform: translateX(-100%);
}
.is-hidden .checkmark::after {
    opacity: 0;
}
.cb_container {
    position: relative;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.cb_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 25px;
    background-color: #eee;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: #fff;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 9%);
}
.checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: #0ab438;  
}
.cb_container:hover input ~ .checkmark {
    background-color: #fff;
}
.cb_container input:disabled ~ .checkmark {
    opacity: 0.3;
    cursor: default;
}
.cb_container input:checked ~ .checkmark {
    background-color: #2196F3;
    background-color: var(--glds-green);
    outline: 0;
}
.cb_container input:checked ~ .checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: #0ab438;
}
.cb_container input:focus ~ .checkmark {
    outline: none;
    border: none;
    box-shadow: 0 0 5px  rgb(119, 141, 169);
    border: 1px solid rgb(119, 141, 169);
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.cb_container input:checked ~ .checkmark:after {
    display: block;
}
.cb_container .checkmark:after {
    left: 9px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.cb_container .checkmark.changed:after {
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.item__details-wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: .5em;
    padding-left: 1em;
    flex: 1;
}
.item__details-wrapper span:first-child {
    font-family: futuraBold;
    font-size: 1.75rem;
    color: var(--base-font-color);
}
.item__details-wrapper span:last-child {
    font-size: 1rem;
    color: var(--heading-light-color);
}



/* 
.license__user__item.is-loading .license__user__item__loading {
    opacity: 1;
}
*/
.license__user__item__banner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--glds-green);    
    font-size: 1.5rem;
    font-family: futurabold;
    color: snow;
    z-index: 5;
    opacity: 0;
    transform-origin: left; 
    transform: translateX(100%) scale(.5);       
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}
.license__user__item__banner.is-success {
    background-color: transparent;
    background-color: var(--glds-green);
    opacity: .9;
}




.service_license-form {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background-color: #c9daea;
    border: 1px solid black;
    border-radius: 10px;
}
.service_license-form h1 {
    text-align: center;
    padding-top: .5em;
    margin: 0;
}
.service_detail-container {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    flex: 1;
}
.agent_select-container {
    padding: 0 4em;
}
.panel-group {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
}
.panel-one {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1em;
}
.panel-two {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1em;
}