.home__page__view {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}
.search-view {
    padding: 1.5em;
}
.search-selection-div {    
    padding: 0.75em 0em;
}
.search-selection-div span {
    font-family: var(--default-head-font);
    font-size: 2.5rem;
    color: var(--primary-dark-color);
}
.search-selector {
    position: relative;
    display: flex;
    justify-content: center;
    /* width: 100%; */
}
.side-padding {
    flex: 0.25;
}
.selector-div {
    display: flex;    
    flex-wrap: nowrap;
    justify-content: center;
    flex: 1;
}
.selector-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.25em;
}
.selector-btn svg {
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.2s;
}
.selector-btn:hover svg {
    transform: scale(1.1);
}
.search-input-div {
    position: relative; 
    width: 75%;
    padding: 0.75em 0em;
    margin: 0 auto;
}
.search-input-div input {
    height: 50px;
    width: 100%;
    padding-left: 0.75em;
    font-size: 25px;
    border-radius: 10px;
}
.search-icon-div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: 0.95em;
    cursor: pointer;
}
.search-icon-div svg {
    transform: scale(1);
    transition: transform 0.2s;
}
.search-icon-div:hover svg {
    transform: scale(1.25);
}

.manage_btn_cell {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    height: 100%; 
}

.table-content-view {
    position: relative;
    flex: 1;
    /* background-color: mediumseagreen; */
}
.table-content-div {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 1.25em;
}
.table {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: lightgray;
}
.add__btn__container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0.75em;
    right: 1.25em;
    z-index: 1;
}
.add__btn {
    position: relative;
}
.add__btn svg {
    transform: scale(1);
    transition: transform 0.2s, backgroundColor 0.2s;
}
.add__btn:hover svg {
    transform: scale(1.1);
    color: limegreen;
    cursor: pointer;
}
.table-content-div {
    position: relative;
    display: flex;
    /* background-color: red; */
}
.homepage__table__container {
    flex: 1;
    background-color: lightgreen;
}
.homepage__table__container .table-style {
    width: 100%;
    height: 100%;
}
.manage_btn_cell {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}
.row_manage_btn, .row_manage_btn:active, .row_manage_btn:focus {
    display: flex;
    border: 0;
    outline: 0;
    background-color: transparent;
    box-shadow: 0;
}
[data-action="edit"] svg,[data-action="update"] svg {
    color: var(--glds-green);
    /* color: limegreen; */
}
[data-action="delete"] svg,[data-action="cancel"] svg {
    color: var(--glds-red);
    /* color: red; */
}
[data-action="view"] svg {
    color: var(--primary-dark-color);
    /* color: red; */
}
.copied__alert {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--glds-green);
    font-family: var(--default-head-font);
    color: snow;
    opacity: 0;
    transform: translateX(100%);
    /* transition:  all 0.5s ease; */
    box-shadow: var(--glds-green) 2px 2px 6px 0px inset, rgba(33, 134, 33,0.5) -2px -2px 6px 1px inset;
}
.copied__alert.show__alert {
    animation: 3s forwards alertSlide;
}

@keyframes alertSlide {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    30% {
        opacity: 1;
        transform: translateX(0);
    }
    50% {
        opacity: 1;
        transform: translateX(0);
    }
    80% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
    
}
.ag-theme-alpine [class^=ag-] {
    overflow-x: hidden;
}