.login-form-col {
    position:relative;
    display:flex;
    flex-flow:column;
    justify-content:center;
    align-items:flex-start;
    height: 100%;
    padding-left:1.5em;
    border-width: 3px;
    border-style: solid;
    border-image:linear-gradient(to bottom,rgba(0, 0, 0, 0),#1B263B,rgba(0, 0, 0, 0)) 1 100%;
}
.login-form-col .flogin {
    position: relative;
}

.login-form-col .flogin label {
    color:white;
    font-family:futuraBold;
}
#pwd-tf, #email-tf {
    font-family: futura;
    font-size: 1.25rem;
}

.login-btn-row {
    display:flex;
    flex-wrap:nowrap;
    justify-content:space-between;
}

.login-btn-row #submit-btn {
    background-color:#1B263B;
    border-color:#1B263B;
    font-family: futura;
}

.login-btn-row #show-keypad-btn {
    background-color:#1B263B;
    border-color:#1B263B;            
}

.login-btn-row #clear-btn {
    background-color:#1B263B;
    border-color:#1B263B;
    font-family: futura;
}

.error {
    position: relative;
    animation: shake .1s linear;
    animation-iteration-count: 3;
    border:1px solid #EF3E36;
    box-shadow: 0 0 10px #EF3E36;
}

.error_msg {
    display: none;
    padding-top: 0.75em;
    font-size: 0.8rem;
    color: #EF3E36;
    text-align: center;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}
.error_msg.hide {
    opacity: 0;
}

.error:focus {
    outline: none !important;
    border:1px solid #EF3E36;
    box-shadow: 0 0 10px #EF3E36;
}



@keyframes shake {
    0% { left: -5px; }
    100% { right: -5px; }
}