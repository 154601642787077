.user__detail__form {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}
.user__detail__form__container {
    position: relative;
    flex: 1;
    display: flex;
    flex-flow: column;
}
.user__detail__form__btn__container {
    position: relative;
    padding: .75em;    
}
.user__detail__btn__container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.75em;
    margin-top: .75em;
}
.user__detail__btn__container button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.user__detail__btn__container button.green {
    background: #00bf8f;
    background: -webkit-linear-gradient(to top, rgba(0, 21, 16,0.4), #00bf8f);
    background: linear-gradient(to top, rgba(0, 21, 16,0.4), #00bf8f);
}
.user__detail__btn__container button.red {
    background: #e65970;
    background: -webkit-linear-gradient(to top, rgba(0, 21, 16,0.4), #e65970);
    background: linear-gradient(to top, rgba(0, 21, 16,0.4), #e65970);
}
.user__detail__btn__container button .btn-icon {
    margin-right: 0.75em;
}