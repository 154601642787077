.menutilecontainer {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5em;
    width: 75%;
    padding: 1.5em;
    /* background-color: #c9daea; */
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}
.menutilecontainer::-webkit-scrollbar {
    display: none;
  }
.icon-shadow {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.336));
}