.checkbox {
    /* Custom properties */
    cursor: pointer;
    position: relative;
    margin-bottom: 1em;
}

.wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/***** CHECKBOX LABEL STYLE *****/
.label:first-child {
    margin-right: .75em;
}
.label  h3 {
    font-size: 1.75rem;
    font-family: 'Secular One', sans-serif;
    color: snow;
    margin: 0;
    text-transform: capitalize;
}


/***** CHECK BOX INPUT STYLE *****/
.input_wrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 60px;
    height: 30px;
}
.input_wrapper:focus-visible {
    outline: 0;
    border: 0;
}
.input {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.input input {    
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/*** CHECKMARK UI STYLES ***/
.checkmark {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0px;    
    height: 30px;
    width: 60px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
}
.checkmark:focus-visible {
    border: 0;
    outline: 0;
}
.checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: green;  
}
.input:hover input ~ .checkmark {
    /* background-color: #fff; */
    background-color: transparent;
}
.input input:disabled ~ .checkmark {
    opacity: 0.3;
    cursor: default;
}
.input input:checked ~ .checkmark {
    background-color: transparent;
    /* background-color: green; */
    /* width: 100%;
    height: 100%; */
}
.input input:checked ~ .checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: var(--accent-green-color);
}
.input input:focus ~ .checkmark {
    outline: 0;
    border: 0;
    box-shadow: 0 0 5px  rgb(119, 141, 169);
    border: 1px solid rgb(119, 141, 169);
    border: 1px solid #0D1B2A;
}
.input input:active ~ .checkmark {
    outline: 0;
    border: 0;
    background-color: transparent;
    /* box-shadow: 0 0 5px  rgb(119, 141, 169); */
    /* border: 1px solid rgb(119, 141, 169); */
}
.checkmark:after {
    content: "";
    position: absolute;
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    transform: translateX(31px);
    transition: transform 0.2s;
}
.input input:checked ~ .checkmark:after {
    background-color: limegreen;
    transform: translateX(0px);
}
.input .checkmark:after {
    background-color: #ED6A5A;     
    transform: translateX(30px);      
}
.input .checkmark.changed:after {
    border: solid black;
    border-width: 0 6px 6px 0;
}
.input:focus-visible {
    border: 0;
    outline: 0;
}
.checkmark:focus-visible {
    border: 0;
    outline: 0;
}