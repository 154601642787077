.add__agent__modal__form {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}
.add__agent__form__container {
    position: relative;
    flex: 1;
    display: flex;
    flex-flow: column;
}
.add__agent__form__btn__container {
    position: relative;
    margin: 0;
    padding: .75em;
}
.add__agent__btn__container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.75em;
    margin-top: .75em;
}
.add__agent__btn__container button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .5em;
}
.add__agent__btn__container button .btn-icon {
    margin-right: 0.75em;
}