.add__organization__view {
    position: relative;
    padding: 0.5em .75em;
}
.add__orginaztion__title {
    padding: 0.5em;
    margin-bottom: .75em;
    text-align: center;
}
.add__orginaztion__title h2 {
    font-family: futuraBold;
    font-size: 2.75rem;
}
.add__organization__form {
    position: relative;
}
.add__organization__form form .row:first-child {
    margin-bottom: 2.25em;
}
.add__organization__form fieldset {
    padding: 0 .75em;
    border: 2px groove black;
    border-radius: 5px;
}
.hidden {
    visibility: hidden;
}
.add__org__btn__container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.75em;
    margin-top: .75em;
}
.add__org__btn__container button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .5em;
}
.add__org__btn__container button .btn-icon {
    margin-right: 0.75em;
}