.we__sqcb__input {
    display: flex;
    flex-wrap: nowrap;
    /* justify-content: flex-end;     */
    width: 60px;
    height: 30px;
}
.we__sqcb__container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.we__sqcb__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.we__sq__checkmark {
    position: absolute;
    right: 0px;
    display: flex;
    align-items: center;
    height: 30px;
    width: 60px;
    border-radius: 5px;
    border: 1px solid black;
}
.we__sq__checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: var(--accent-green-color);  
}
.we__sqcb__container:hover input ~ .we__sq__checkmark {
    /* background-color: #fff; */
    background-color: transparent;
}
.we__sqcb__container input:disabled ~ .we__sq__checkmark {
    opacity: 0.3;
    cursor: default;
}
.we__sqcb__container input:checked ~ .we__sq__checkmark {
    background-color: transparent;
    /* background-color: green; */
    /* width: 100%;
    height: 100%; */
}
.we__sqcb__container input:checked ~ .we__sq__checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: var(--accent-green-color);
}
.we__sqcb__container input:focus ~ .we__sq__checkmark {
    outline: none;
    border: none;
    box-shadow: 0 0 5px  rgb(119, 141, 169);
    border: 1px solid rgb(119, 141, 169);
}
.we__sqcb__container input:active ~ .we__sq__checkmark {
    outline: none;
    border: none;
    background-color: transparent;
    /* box-shadow: 0 0 5px  rgb(119, 141, 169); */
    /* border: 1px solid rgb(119, 141, 169); */
}
.we__sq__checkmark:after {
    content: "";
    position: absolute;
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    transform: translateX(31px);
    transition: transform 0.2s;
}
.we__sqcb__container input:checked ~ .we__sq__checkmark:after {
    background-color: var(--accent-green-color);
    transform: translateX(0px);
}
.we__sqcb__container .we__sq__checkmark:after {
    background-color: #ED6A5A;     
    transform: translateX(30px);  
    
}
.we__sqcb__container .we__sq__checkmark.changed:after {
    border: solid black;
    border-width: 0 6px 6px 0;
}