html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
}
* , *:before, *:after{ 
    box-sizing:border-box; 
    -moz-box-sizing:border-box; 
    -webkit-box-sizing:border-box; 
    -ms-box-sizing:border-box;
}

@font-face {
  font-family: futura;
  src: url("./fonts/futuraPTBook.otf") format("opentype");
}
@font-face {
    font-family: futuraBold;
    src: url("./fonts/futuraBold.otf") format("opentype");
}

:root {
	font-family: futura;
    --glds-green: #00a651;
    --glds-red: #DA2C38;
	--primary-dark-color: #0D1B2A;
	--primary-lite-dark-color: #415A77;
	--primary-lite-color: #c9daea;
    --defaut-font: futura;
    --tooltip-font: 'Azeret Mono', monospace;
    --base-font-color: snow;
    --default-head-font: futurabold;
    --heading-bg-color: #415A77;
    --tile-bg-color: #415A77;
    --button-bg-color: #0D1B2A;
    --heading-light-color: #c9daea;
    --icon-light-color: #48ACF0;
    --color-cadmium-red-secondary: #D22B2B;
    width: 100vw;
    height: 100vh;
}

@media screen and (min-height: 500px) {
    html {
        font-size: 4px;
    }
}
@media screen and (min-height: 675px) {
    html {
        font-size: 4px;
    }
}
@media screen and (min-height: 700px) {
    html {
        font-size: 11px;
    }
}

.glds__btn {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    /* width: 300px;
    height: 50px; */
    margin: 0;
    padding: 0.25em 0.75em;
    outline: none;
    border: 0;
    border-radius: 0.25rem;
    background-color: var(--primary-lite-dark-color);
    box-shadow: none;
    font-weight: 400;
    line-height: 1.5;
    font-family: futura;
    font-size: 1.25rem;
    color: snow;
    box-shadow: 0 8px 6px -6px black;
    transition: box-shadow 0.4s ease;
    z-index: 1;
}
.accept__button {
    width: 100%;
    color: snow;
    background-color: var(--glds-green);
 }

.danger__button {
    width: 100%;
    color: snow;
    background-color: var(--glds-red);
}

.glds__btn::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: .25rem;
}


/* Button component css */
.glds__btn:hover {
    box-shadow: 4px 12px 24px -4px black;
    transition: box-shadow 0.4s ease;  
}
.glds__btn:focus::after {
    background-color: rgba(13, 27, 42, 0.5);
    z-index: -1;
}
.glds__btn:active {
    box-shadow: inset 8px 8px 18px -6px black;
}

/***** Application animation key frames *****/
@keyframes shake {
    0% { left: -5px; }
    100% { right: -5px; }
}