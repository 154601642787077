.user_list {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: .25em .25em;
    padding-bottom: 0;
}


.heading {
    position: relative;
    padding: .2em;
    padding-top: 0;
    text-align: center;
}
.heading h2 {
    font-family: var(--default-head-font);
    font-size: 2.25rem;
    margin: 0;
}
.sub_heading {
    padding-bottom: .25em;
    text-align: center;
}



.body {
    position: relative;
    /* display: flex;
    flex-flow: column; */
    width: 100%;
    flex: 1;
    /* padding: 0 .75em;  */
    /* background-color: #0ab438;   */
}
.body h4 {
    font-size: 1.25rem;
    margin-bottom: .25em
}
.body ul {
    position: relative;
    display: block;
    padding: 0;
    padding: .75em 1.25em;
    /* width: 100%; */
    /* background-color: blueviolet; */
}
.table_container {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 5px;
    overflow-y: scroll;
}
.table_container::-webkit-scrollbar {
    width: 0;
}






.list_item {
    position: relative;
    display: flex;    
    width: 100%;
    margin-bottom: 1.25em;
    padding: .75em;
    border: 1px solid var(--primary-dark-color);
    border-radius: 0.25em;
    background-color: var(--primary-lite-dark-color);
    overflow: hidden;
    cursor: pointer;
}
ul .list_item:last-child {
    margin-bottom: 0;
}
.item_cb {
    position: relative;
    display: flex;
    align-items: center;
    padding: .25em;
}
.item_details {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: .5em;
    padding-left: 1em;
    flex: 1;
}
.item_details span:first-child {
    font-family: futuraBold;
    font-size: 1.75rem;
    color: var(--base-font-color);
}
.item_details span:last-child {
    font-size: 1rem;
    color: var(--heading-light-color);
}




.list_details{
    padding: 0.75em;
    font-size: 1.25rem;
    font-family: 'futuraBold';
    display: flex;
    width: 100%;
    justify-content: space-around;
}