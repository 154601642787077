.menutoolbar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #0D1B2A;    
}
.menutoolbar h3 {
    margin: 0px;
    padding: 0px;
    color: snow;
    font-family: futuraBold;
    font-size: 1.5rem;
}