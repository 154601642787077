.admin__view {
    position: absolute;
    display: flex;
    top: 0px;
    left: 0px;   
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /* background-color: aquamarine; */
}
nav.left__nav {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 200px;    
    border-right: 2px solid black;
    background-color: var(--primary-lite-dark-color);
}
.left__nav__logo {
    padding: .75em;
    padding-bottom: .80em;
    background-color: var(--primary-dark-color);
    cursor: pointer;    
}
.left__nav__main {
    flex: 1;
}
.left__nav h1 {
    text-align: center;
    font-size: 1.5rem;
    padding: 0.5em;
    margin: 0;
    color: snow;
    background-color: var(--primary-dark-color);
    font-family: var(--default-head-font);
}
.left__nav ul {
    width: 100%;
    padding: 0px;
}
.left__nav li {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: 1px solid var(--primary-lite-color);
    font-size: 1.25rem;
    color: snow;
}
.left__nav li a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    /* padding: 0.5em; */
    color: snow;
    margin: 0;
    text-decoration: none;
    z-index: 1;
}

.left__nav li:hover {
    cursor: pointer;
    background-color: rgba(95, 158, 160, 0.322);
}
.left__nav li:hover svg {
    transform: rotate(5deg) scale(1.5);
}
.left__nav li.active {
    background-color: rgba(50, 205, 50, 0.438);
    box-shadow: rgba(0, 0, 0, 0.651) 2px 2px 10px 1px inset, rgba(50, 205, 50, 0.233) -2px -2px 10px 1px inset;
    /* border-bottom: 1px solid var(--primary-lite-color); */
}





/***** Left nav footer *****/
.left__nav__footer {    
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;    
    background-color: var(--primary-dark-color);
}
.log__out__link {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0.5em;
    border-top: 1px solid var(--primary-lite-color);
    font-size: 1.25rem;
    color: snow;
    background-color: var(--primary-lite-dark-color);
    cursor: pointer;
}
.log__out__link:hover {
    background-color: rgba(95, 158, 160, 0.322);
}
.log__out__link div {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 .25em;
}

.left__nav__footer span {
    padding: 0.25em 0.5em;
    font-family: var(--default-font);
    font-size: 0.75rem;
    font-weight: 700;
    color: lightgray;
}