.license__detail-view {
    position: relative; 
    display: flex;
    flex-flow: column;       
    width: 100%;
    height: 100%;
    padding: 0.5em .75em;
    overflow-y: scroll;
    box-sizing: border-box;
}
.license__detail-view nav {
    position: relative;
    margin: 0.75em 0;
    box-sizing: border-box;
}
.license__detail-view nav .nav__row {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    /* width: 100%; */
    height: 100%;
    /* background-color: red; */
}
.license__detail-view nav .nav__row .nav__icon {
    position: relative;
    padding: 0.5em;
    cursor: pointer;
    border: 1px solid transparent;
}

.license__detail-view nav .nav__row .nav__icon:hover {
    border: 1px solid darkblue;
    border-radius: 10px;
    box-shadow: inset 0 0 40px green;
    color: darkgreen;
}
.license__detail-view nav .nav__row .nav__icon.active {
    border: 1px solid darkblue;
    border-radius: 10px;
    box-shadow: inset 0 0 40px green;
    color: darkgreen;
}
.license__detail-view .title {
    padding: 0.5em;
    text-align: center;
}
.license__detail-view .title h2 {
    font-family: futuraBold;
    font-size: 2.75rem;
}
.license__detail-view .form {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
}
.license__detail-view .form form {
    position: relative;
    height: 100%;
}
.cb_field {
    justify-content: center;
}
.add__user__form__admin__cb {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.add__user__form__admin__cb div:first-child {
    margin-right: 2em;
}
.add__user__form__admin__cb h3 {
    margin: 0;
    padding: 0;
}

#license-detail-view .btm__nav {
    position: relative;
    width: 100%;
    padding: .75em .5em;
}

#license-detail-view .license__users__assign-view {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: 0 .25em;
    /* background-color: blueviolet; */
    
}

#license-detail-view .license__users__assign-view .heading {
    position: relative;
    padding: 1em;
    padding-top: 0;
    text-align: center;
}
#license-detail-view .license__users__assign-view .heading h2 {
    font-family: var(--default-head-font);
    font-size: 2.25rem;
    margin: 0;
}
#license-detail-view .license__users__assign-view .option {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .5em;
}
#license-detail-view .license__users__assign-view .body {
    position: relative;
    display: flex;
    flex-flow: column;
    flex: 1;
    padding: 0 .75em;   
}
#license-detail-view .license__users__assign-view .body h4 {
    font-size: 1.25rem;
}
#license-detail-view .license__users__assign-view .body ul {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1.25em;
    border: 1px solid black;
    border-radius: 5px;
}
#license-detail-view .license__users__assign-view .dropdown {
    position: relative;
    padding: 1em;
}
/* .dropdown-menu {
    width: 200px !important;
    height: 400px !important;
} */
#license-detail-view .list-group-item {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding-left: 0;
    border-top: 1px solid rgba(255, 250, 250, 0.5); 
}
#license-detail-view .list-group-item:last-child {
    border-bottom: 1px solid rgba(255, 250, 250, 0.5);
}
#license-detail-view .list-group-item > div:first-child {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 .75em;
}
#license-detail-view .list-group-item > div:last-child div h6 {
    font-family: var(--default-head-font);
    margin: 0;
}
#license-detail-view .list-group-item > div:last-child div span {
    font-size: .8rem;
    color: silver;
}



/***** Licensed user list styles *****/
#license-detail-view .license__user__item {
    position: relative;
    display: flex;    
    width: 100%;
    margin-bottom: 1.25em;
    border: 1px solid var(--primary-dark-color);
    border-radius: 0.25em;
    background-color: var(--primary-lite-dark-color);
    overflow: hidden;
    cursor: pointer;
}
#license-detail-view .license__user__item.is-error {
    background-color: var(--primary-lite-dark-color);
    animation: shake .09s linear 4;
}
#license-detail-view .license__user__item .license__user__item__banner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--glds-green);    
    font-size: 1.5rem;
    font-family: futurabold;
    color: snow;
    z-index: 5;
    opacity: 0;
    transform-origin: left; 
    transform: translateX(100%) scale(.5);       
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}
#license-detail-view .license__user__item .license__user__item__banner.is-error {
    background-color: transparent;
    background-color: var(--glds-red);
    opacity: .9;
}
#license-detail-view .license__user__item .license__user__item__banner.is-success {
    background-color: transparent;
    background-color: var(--glds-green);
    opacity: .9;
}

#license-detail-view .license__user__item .license__user__item__banner.is-visible {    
    opacity: 1;
    transform: translateX(0) scale(1);
    transform-origin: right;
}
#license-detail-view .license__user__item.is-loading {
    
    opacity: 0.5;
}
#license-detail-view .license__user__item .license__user__item__loading {
    position: absolute;
    top: 50%;
    right: .75em;
    transform: translateY(-50%);
    opacity: 0;
}
#license-detail-view .license__user__item.is-loading .license__user__item__loading {
    opacity: 1;
}

#license-detail-view .license__user__item .license__user__item__cb-wrapper {
    position: relative;
    display: flex;    
    justify-content: center;
    align-items: center;
    padding: .75em;        
}
#license-detail-view .license__user__item .license__user__item__cb-wrapper.is-hidden {
    width: 0;
    padding: 0;
}
#license-detail-view .license__user__item .license__user__item__cb-wrapper.is-hidden input { 
    opacity: 0;
}
#license-detail-view .license__user__item .license__user__item__cb-wrapper.is-hidden .checkmark {
    position: relative;
    transform: translateX(-100%);
} 
#license-detail-view .license__user__item .license__user__item__cb-wrapper.is-hidden .checkmark::after {
    opacity: 0;
}
#license-detail-view .license__user__item .license__user__item__details-wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: .75em;
    flex: 1;
}
.license__user__item .license__user__item__details-wrapper span:first-child {
    font-family: futuraBold;
    font-size: 1.75rem;
    color: var(--base-font-color);
}
.license__user__item .license__user__item__details-wrapper span:last-child {
    font-size: 1rem;
    color: var(--heading-light-color);
}
.btn-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: .25em;
}

.btn-content.cancel {
    color: black;
}
.btn-content.save {
    color: black;
}
.btn-content:hover,
.btn-content.cancel:hover {
    color: snow;
}
.btn-content.save span:first-child,
.btn-content.cancel span:first-child {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .25em;
}
.btn-content.save span:last-child,
.btn-content.cancel span:last-child {
    margin-left: .25em;
}


/* checkbox styles */
.checkbox-area {
    position: relative;
    height: 100%;
    
    
}                
.container {
    position: relative;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 25px;
    background-color: #eee;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: #fff;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 9%);
}
.checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: #0ab438;  
}
.container:hover input ~ .checkmark {
    background-color: #fff;
}
.container input:disabled ~ .checkmark {
    opacity: 0.3;
    cursor: default;
}
.container input:checked ~ .checkmark {
    background-color: #2196F3;
    background-color: var(--glds-green);
    outline: 0;
}
.container input:checked ~ .checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: #0ab438;
}
.container input:focus ~ .checkmark {
    outline: none;
    border: none;
    box-shadow: 0 0 5px  rgb(119, 141, 169);
    border: 1px solid rgb(119, 141, 169);
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.container input:checked ~ .checkmark:after {
    display: block;
}
.container .checkmark:after {
    left: 9px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.container .checkmark.changed:after {
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}